import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import store from '../store'
import { Role } from '@/helpers/role';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeAuth',
    component: () => import(/* webpackChunkName: "homeauth" */ '../views/dashboard')
    // meta: { authorize: [Audience.Dashboard] }
  },
// {
//   path: '/home',
//   name: 'Home',
//   component: () => import(/* webpackChunkName: "home" */ '../views/dashboard')
// },    
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '/v-tabelle',
    name: 'Tabelle',
    component: () => import(/* webpackChunkName: "tabelle" */ '../views/tabelle'),
    meta: { authorize: [Role.Admin, Role.Tabelle] }
  },
  {
    path: '/v-tabelle/asseverazioni',
    name: 'Statistica asseverazioni',
    component: () => import(/* webpackChunkName: "statisticaAsseverazioni" */ '../views/tabelle/statisticaAsseverazioni'),
    meta: { authorize: [Role.Admin, Role.Tabelle] }
  },
  {
    path: '/v-tabelle/task',
    name: 'Statistica task',
    component: () => import(/* webpackChunkName: "statisticaTask" */ '../views/tabelle/statisticaTask'),
    meta: { authorize: [Role.Admin, Role.Tabelle] }
  },
  {
    path: '/v-tabelle/target',
    name: 'Target',
    component: () => import(/* webpackChunkName: "target" */ '../views/tabelle/entiAlTarget'),
    meta: { authorize: [Role.Admin, Role.Tabelle] }
  },
  {
    path: '/v-grafici',
    name: 'Grafici',
    component: () => import(/* webpackChunkName: "grafici" */ '../views/grafici'),
    meta: { authorize: [Role.Admin, Role.Grafici] }
  },
  {
    path: '/v-impostazioni',
    name: 'Impostazioni',
    component: () => import(/* webpackChunkName: "impostazioni" */ '../views/impostazioni'),
    meta: { authorize: [Role.Admin] }
  },
  {
    path: '/v-enti-asseverabili',
    name: 'EntiAsseverabili',
    component: () => import(/* webpackChunkName: "entiasseverabili" */ '../views/enti'),
    meta: { authorize: [] }
  },
  // {
  //   path: '/editor',
  //   name: 'Editor',
  //   component: () => import(/* webpackChunkName: "editor" */ '../views/editor')
  //   // meta: { authorize: [Role.Editor] }
  // },  
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  // const currentUser1 = store.getters['utenti/info']
  const currentUser = await store.dispatch('utenti/verifyAndGetUser')
  if (authorize) {
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return next({ path: '/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !_.intersection(authorize, currentUser.ruoli).length) {
        // role not authorised so redirect to home page
        return next({ path: '/' });
    }
}

  next()
})

/* router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  let currentDevice = store.getters['mobile/device']
  if (authorize) {
    if (!currentDevice || to.name === 'Register') {
      await store.dispatch('mobile/deviceLogin', { apiKey: to.params.key, moduleKey: to.params.module, base: to.params.base, device: to.params.device, service: to.query.service === 'true'})
      // await store.dispatch('mobile/deviceLoginToken', { registerToken: to.query.tk, device: to.params.device })
      currentDevice = store.getters['mobile/device']
    } else {
      await store.dispatch('mobile/testTokenExpiration')
    }
    if (!currentDevice || (authorize.length && !_.intersection(authorize, currentDevice.audience).length)) {
      return next({ path: '/home' });
    }
  }

  next()
}) */

export default router
